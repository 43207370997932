import { Link, useParams } from "react-router-dom";
import { DoctorCard } from "../utils/cards";
import { doctorsList } from "../utils/utilities";
import { useEffect, useState } from "react";

let depImages = {
    cardiology : ['tab-content-2.jpg', 'tab-content-4.jpg', 'tab-content-1.jpg', 'tab-content-3.jpg', 'tab-content-5.jpg'],
    neurology : ['DSC_2217-deparment.jpg', 'DSC_2218-deparment.jpg', 'DSC_2256-deparment.jpg', 'DSC_2214-deparment.jpg'],
    criticalCare: ['DSC_2349-department.jpg', 'DSC_2347-department.jpg', 'DSC_9654-department.jpg'],
};

const departments = {
    cardiology: { title: 'Cardiology', mainImg: 'tab-content-1.jpg', link: 'cardiology', images: depImages.cardiology },
    gynecology: { title: 'Gynecology', mainImg: 'tab-content-2.jpg', link: 'gynecology', images: depImages.cardiology },
    urology: { title: 'Urology', mainImg: 'tab-content-3.jpg', link: 'urology', images: depImages.cardiology },
    neurology: { title: 'Neurology', mainImg: 'tab-content-4.jpg', link: 'neurology', images: depImages.neurology },
    nephrology: { title: 'Nephrology', mainImg: 'tab-content-5.jpg', link: 'nephrology', images: depImages.cardiology },
    orthopedics: { title: 'Ortho Paedics', mainImg: 'tab-content-6.jpg', link: 'orthopedics', images: depImages.cardiology },
    pediatrics: { title: 'Pediatrics', mainImg: 'tab-content-2.jpg', link: 'pediatrics', images: depImages.cardiology },
    obstetrics: { title: 'Obstetrics', mainImg: 'tab-content-3.jpg', link: 'obstetrics', images: depImages.cardiology },
    genSurgery: { title: 'General Surgery', mainImg: 'tab-content-4.jpg', link: 'genSurgery', images: depImages.cardiology },
    criticalCare: { title: 'Critical Care', mainImg: 'DSC_2349-department.jpg', link: 'criticalCare', images: depImages.criticalCare },
}

const Gynecology = ({ match }) => {

    const params = match.params;
    const [active, setActive] = useState('cardiology');
    const department = departments[active];
    const [activeSlide, setActiveSlide] = useState(department.images[0]);

    useEffect(() => {
        if (!departments[params.service]) return;
        setActive(params.service);
        let x = Math.round(Math.random() * 4);
        setActiveSlide(department.images[x]);
    }, [params.service])

    return (
        <div className="bsn-global">
            <section className="breadcrumb-area" style={{backgroundImage: 'url(/assets/img/aboutUs/aboutus-background.jpg)'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumbs">
                                <h1>{department.title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="left">
                                    <ul>
                                        <li><Link to="/">Home</Link></li>
                                        <li><span className="material-symbols-outlined">navigate_next</span></li>
                                        {/* <li><Link to="/services">Department</Link></li>
                                        <li><span className="material-symbols-outlined">navigate_next</span></li> */}
                                        <li className="active">{department.title}</li>  
                                    </ul>
                                </div>
                                <div className="right">
                                    <a href="#"><span className="material-symbols-outlined">share</span> Share</a>   
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="departments-single-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 pull-right">  
                            <div className="tab-box">
                                <div className="tab-content">
                                    {Object.values(departments).map(i => (
                                        <div className={`tab-pane ${i.link === department.link ? 'active' : ''}`} key={i.link}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="img-box">
                                                        <img src={`/assets/img/BSN/${activeSlide}`} alt="Awesome Image"/>
                                                    </div>    
                                                </div>      
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <ul className="nav nav-tabs tab-menu">
                                    {department.images.map(image => (
                                        <li className={activeSlide === image ? 'active' : ''} key={image} onClick={() => setActiveSlide(image)}>
                                            <a href="#" data-toggle="tab">
                                                <div className="img-holder">
                                                    <img src={`/assets/img/BSN/${image}`} alt="Awesome Image"/>
                                                    <div className="overlay-style-one">
                                                        <div className="box">
                                                            <div className="content">
                                                                <div className="iocn-holder">
                                                                    <span className="flaticon-plus-symbol"></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}
                                    {/* <li className="active">
                                        <a href="#ds2" data-toggle="tab">
                                            <div className="img-holder">
                                                <img src="/assets/img/departments/cardiology/tab-menu-2.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <div className="iocn-holder">
                                                                <span className="flaticon-plus-symbol"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>    
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#ds3" data-toggle="tab">
                                            <div className="img-holder">
                                                <img src="/assets/img/departments/cardiology/tab-menu-3.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <div className="iocn-holder">
                                                                <span className="flaticon-plus-symbol"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#ds4" data-toggle="tab">
                                            <div className="img-holder">
                                                <img src="/assets/img/departments/cardiology/tab-menu-4.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <div className="iocn-holder">
                                                                <span className="flaticon-plus-symbol"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#ds5" data-toggle="tab">
                                            <div className="img-holder">
                                                <img src="/assets/img/departments/cardiology/tab-menu-5.jpg" alt="Awesome Image"/>
                                                <div className="overlay-style-one">
                                                    <div className="box">
                                                        <div className="content">
                                                            <div className="iocn-holder">
                                                                <span className="flaticon-plus-symbol"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </li> */}
                                </ul>
                            </div>
                            
                            <div className="text-box mt-4">
                                <p>Explain to you how all this mistaken idea of denouncing pleasure and praising pain was born wewill give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure sed rationally encounter consequences that are extremely painful.</p>
                                <p>Denouncing pleasure and praising pain was born wewill give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness rationally.</p>
                            </div> 
                            
                            <div className="pricing-box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-box">
                                            <h3>Investigation</h3>
                                            <ul>
                                                <li>Gastroscopy .......... <span>$320</span></li>
                                                <li>Colonoscopy .......... <span>$145</span></li>
                                                <li>Bronshoscopy .......... <span>$270</span></li>
                                                <li>Paratyroid Scan .......... <span>$80</span></li>
                                                <li>Allergy Testing .......... <span>$385</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-box">
                                            <h3>Treatments</h3>
                                            <ul>
                                                <li>Paratyroid Scan .......... <span>$80</span></li>
                                                <li>Allergy Testing .......... <span>$385</span></li>
                                                <li>Colonoscopy .......... <span>$145</span></li>
                                                <li>Gastroscopy .......... <span>$320</span></li>
                                                <li>Bronshoscopy .......... <span>$270</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            
                            {/* <div className="service-plan">
                                <div className="sec-title">
                                    <h1>Our Services</h1>
                                    <span className="border"></span>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-box">
                                            <div className="icon-holder">
                                                <span className="material-symbols-outlined">ambulance</span>
                                            </div>
                                            <div className="text-box">
                                                <h3>24 Hrs Ambulance</h3>
                                                <p>How all this mistaken idea denoucing pleasure and praisings pain was born complete account expound.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-box">
                                            <div className="icon-holder">
                                                <span className="material-symbols-outlined">ramen_dining</span>
                                            </div>
                                            <div className="text-box">
                                                <h3>Food &amp; Dietry</h3>
                                                <p>There anyone who loves or pursues or to obtain pain of itself, because it is but because occasionally.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-box">
                                            <div className="icon-holder">
                                                <span className="material-symbols-outlined">clinical_notes</span> 
                                            </div>
                                            <div className="text-box">
                                                <h3>Special Nurses</h3>
                                                <p>Pursues or desires to obtain pain itself, because is pain, because occasionally circumstances occur procure.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-box">
                                            <div className="icon-holder">
                                                <span className="material-symbols-outlined">temple_hindu</span> 
                                            </div>
                                            <div className="text-box">
                                                <h3>Places of Worship</h3>
                                                <p>Undertakes laborious physical exercise, except to obtain some advantage from it but who has any right.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}  
                        </div> 
                        
                        <div className="col-lg-3 col-md-4 col-sm-7 col-xs-12 pull-left">
                            <div className="departments-sidebar">
                                <div className="single-sidebar">
                                    <div className="title">
                                        <h3>Departments</h3>    
                                    </div>
                                    <ul className="all-departments">
                                        {Object.values(departments).map(i => (
                                            <li className={department.link === i.link ? 'active' : ''} key={i.title} onClick={() => setActive(i.link)}>
                                                <Link to={`/services/${i.link}`}>{i.title}</Link>
                                            </li>
                                        ))}
                                        {/* <li className="active">
                                            <Link to="/departments/gynecology">Gynecology</Link>
                                        </li>
                                        <li>
                                            <Link to="/departments/pulmonology">Pulmonology</Link>
                                        </li>
                                        <li>
                                            <Link to="/departments/neurology">Neurology</Link>
                                        </li>
                                        <li>
                                            <Link to="/departments/urology">Urology</Link>
                                        </li>
                                        <li>
                                            <Link to="/departments/gastrology">Gastrology</Link>
                                        </li>
                                        <li>
                                            <Link to="/departments/pediatrician">Pediatrician</Link>
                                        </li>
                                        <li>
                                            <Link to="/departments/laboratory">Laboratory</Link>
                                        </li> */}
                                    </ul>
                                </div> 
                                <div className="single-sidebar">
                                    <div className="title">
                                        <h3>Opening Hours</h3>    
                                    </div>
                                    <ul className="opening-time">
                                        <li>Mon to Friday: <span>09.00 to 18.00</span></li>
                                        <li>Saturday: <span>10.00 to 16.00</span></li>
                                        <li>Sunday: <span>10.00 to 14.00</span></li>
                                    </ul>
                                </div> 
                                <div className="single-sidebar">
                                    <div className="title">
                                        <h3>Quick Contact</h3>    
                                    </div>
                                    <div className="contact-us">
                                        <ul className="contact-info">
                                            <li>
                                                <div className="icon-holder">
                                                    <span className="material-symbols-outlined">pin_drop</span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Opposite Bankura Court,<br/>Bankura, West Bengal</h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon-holder">
                                                    <span className="material-symbols-outlined">forward_to_inbox</span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>Getwell@Hospitals.com</h5>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon-holder">
                                                    <span className="material-symbols-outlined">phone_in_talk</span>
                                                </div>
                                                <div className="text-holder">
                                                    <h5>(123) 0200 12345 &amp; 7890</h5>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div> 
                            </div>    
                        </div>
                    </div>
                    <div className="team-area doctor">
                        <div className="sec-title">
                            <h1>Meet Our Doctors</h1>
                            <span className="border"></span>
                        </div>
                        <div className="row">
                            {doctorsList.slice(0, 4).map(i => (
                                <div className="col-lg-3 col-md-4 col-sm-4 col-xs-12" key={i.id}>
                                    <DoctorCard data={i} />
                                </div>
                            ))}
                        </div>
                    </div> 
                </div>
            </section>
        </div>
    )
}

export default Gynecology;