import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { modalAction, loginStatusAction, loaderAction, userInfoAction } from "../../../../actions";
// import axios from "axios";
// import { JQDatePicker, createDate, getDuration, handleNumberInputs, useFetch } from "../utilities";


const PrescriptionUpload = ({modalAction, isLoggedIn, loginStatusAction, compCode, loaderAction, userInfoAction, userInfo}) => {

    const [file, setFile] = useState();
    const [uploadedFileURL, setUploadedFileURL] = useState(null);
  
    function handleChange(event) {
        setFile(event.target.files[0]);
    }
  
    function handleSubmit(event) {
        event.preventDefault()
        const url = 'http://localhost:3000/uploadFile';
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileName', file.name);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        console.log(file);
        // axios.post(url, formData, config).then((response) => {
        //     setUploadedFileURL(response.data.fileUrl);
        // });
    }

    return (
        <section className="presc-upload-box">
            <h1><i className="bx bxs-cloud-upload"></i> Upload Prescription</h1>
            <div className="">
                <div className="card">
                    <div className="card-header">
                        <h4>Upload</h4>
                    </div>
                    <div className="card-body">
                        <h5>Please upload images of a valid prescription from your doctor.</h5>
                        <div>
                            <div className="upload-box">
                                <label className="upload-item" htmlFor="img-input">
                                    <i className="bx bx-file"></i>
                                    <p className="mb-0">click to select</p>
                                </label>
                            </div>
                            <input type="file" className="d-none" onChange={handleChange} accept="image/png, image/jpeg" id="img-input"/>
                        </div>
                        {uploadedFileURL && <img src={uploadedFileURL} alt="Uploaded content"/>}
                        <p>Always upload clean version of the prescription to get better results.</p>
                        <button type="submit" className="add-to-cart d-block ms-auto" onClick={handleSubmit} style={{padding: '0.8em 3em'}}>Upload File</button>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h4>Guide for Valid prescription</h4>
                    </div>
                    <div className="card-body card-body presc-img-box">
                        <img className="img-fluid" src="/assets/img/ePharma/presc.png" alt="prescription example" style={{boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px'}} />
                    </div>
                    <div className="card-footer">
                        <h5>Doctor signature & stamp</h5>
                        <p className="mb-0">The prescription with a signature and/or stamp of the doctor is considered as valid.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToPrescriptionUpload = (state) => {
    return { isLoggedIn: state.isLoggedIn, compCode: state.compCode, userInfo: state.userInfo };
}

export default connect(mapStateToPrescriptionUpload, {modalAction, loginStatusAction, loaderAction, userInfoAction})(PrescriptionUpload);


